import React from "react";
import Cell from "./Cell";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

class Grid extends React.Component {
	state = { value: false };

	/* Setup Lists */
	MEMBERLIST = ["현석", "지훈", "요시", "준규", "재혁", "아사히", "도영", "하루토", "정우", "정환"];
	currentList;

	initialize = () => {
		let cpList = [<Cell name={""} row={0} col={0} key={0} />];
		for (let i = 0; i < this.MEMBERLIST.length; i++) {
			cpList.push(
				<Cell name={this.MEMBERLIST[i]} row={i + 1} col={0} isHeader={true} key={i + 1} />
			);
		}

		for (let i = 1; i < this.MEMBERLIST.length + 1; i++) {
			let j = 1;
			cpList.push(
				<Cell name={this.MEMBERLIST[i - 1]} row={j - 1} col={i} isHeader={true} key={-i} />
			);
			for (; j < this.MEMBERLIST.length + 1; j++) {
				let couple = this.MEMBERLIST[j - 1] + "×" + this.MEMBERLIST[i - 1];
				cpList.push(
					<Cell
						name={couple}
						row={j - 1}
						col={i - 1}
						key={i + "x" + j}
						code={this.props.currentColor}
					/>
				);
			}
		}

		this.currentList = cpList;
	};

	resetView = () => {};

	render() {
		this.initialize();
		return (
			<div id="grid-wrapper">
				<div className="title">틎페스 취향표</div>
				<div id="main-grid">{this.currentList}</div>
			</div>
		);
	}
}
export default Grid;
