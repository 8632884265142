import "./App.scss";
import React, { useState } from "react";

import html2canvas from "html2canvas";

import Grid from "./Grid";
import Categories from "./Categories";

function App() {
	/* Type of Category */
	const [currentType, setCurrentType] = useState("scale_0");
	const [currentColor, setCurrentColor] = useState("#ffb1b4");

	let types = [
		{ type: "나와 한몸으로 간주 (OTP)", color: "#ffb1b4", class: "scale_0" },
		{ type: "메인은 아니어도 주로 생산함", color: "#fcd5bc", class: "scale_1" },
		{ type: "캐해는 아직 잘 모르겠지만 어쩐지 호감", color: "#fef3b3", class: "scale_2" },
		{ type: "생산은 못하겠는데 자주 서치함", color: "#fdb2ff", class: "scale_3" },
		{ type: "탐라에 들어왔을 때 눈팅하거나 반응함", color: "#afe3fe", class: "scale_4" },
		{ type: "탐라에 들어왔을 때 스루 가능", color: "#c1fdb4", class: "scale_6" },
		{ type: "관심 없음", color: "#c1c1c1", class: "scale_5" },
		{ type: "지뢰 (먹으면 내 계정 폭발)", color: "rgb(50, 50, 50)", class: "scale_8" },
	];

	const [typeSet, setTypeSet] = useState(types);

	let selectType = (type) => {
		setCurrentType(type.class);
		setCurrentColor(type.color);
	};

	let categories = [];

	let categoryShorthand = [];
	for (let i = 0; i < typeSet.length; i++) {
		categories.push(
			<Categories.Category
				isSelected={currentType === typeSet[i].class ? true : false}
				class={typeSet[i].class}
				type={typeSet[i].type}
				color={typeSet[i].color}
				onClick={() => {
					selectType(typeSet[i]);
				}}
				key={typeSet[i].class}
			/>
		);

		categoryShorthand.push(
			<Categories.CategoryShorthand
				class={typeSet[i].class}
				type={typeSet[i].type}
				color={typeSet[i].color}
				onClick={() => {
					selectType(typeSet[i]);
				}}
				key={typeSet[i].class}
			/>
		);
	}

	/* Downloadable Image generator */
	const ref = React.createRef();

	let downloadable = new Image();
	const [finished, setFinished] = useState(false);

	let finishGeneration = () => {
		setFinished(true);
		download();
	};

	let download = () => {
		const element = ref.current;

		html2canvas(element).then((canvas) => {
			downloadable.src = canvas.toDataURL("image/png");
		});
		document.querySelector(".App").append(downloadable);
	};

	return (
		<div className="App" style={finished ? { padding: "30px", width: "100%" } : {}}>
			<div id="credit">
				원본: <a href="https://svt-rps-sheet.pages.dev/">셉페스 취향표 만들기</a> by 교수 (
				<a href="https://twitter.com/phdminorstudies">@phdminorstudies</a>)
			</div>

			{finished ? (
				<div id="final-wrapper">
					<div>이미지를 마우스 우클릭하거나 꾹 눌러서 다운로드하세요.</div>
					<div
						className="buttons"
						onClick={() => {
							window.location.reload();
						}}>
						다시 만들기 🔄
					</div>
				</div>
			) : (
				<div>
					<div id="categories-wrapper">
						<div id="categories">{categories}</div>
					</div>
					{/* <div id="grid-wrapper">
				<div class="title">셉페스 취향표</div>
				<div id="main-grid">{this.currentList}</div>
			</div> */}
					<div id="capture-wrapper">
						<div id="capture-area" ref={ref}>
							<Grid currentColor={currentColor} />

							<div id="category-display">{categoryShorthand}</div>
						</div>
					</div>
					<div
						id="finishbutton"
						className="buttons"
						onClick={() => {
							finishGeneration();
						}}>
						이미지 만들기 📸
					</div>
				</div>
			)}
		</div>
	);
}

export default App;
